import { useEffect } from 'react';
import { loginRequest } from '../authConfig';

const SignIn = ({ instance }) => {
    useEffect(() => {
        setTimeout(() => {
            instance.config.auth.redirectUri = '/';
            instance.loginRedirect(loginRequest);
        }, 100);
    }, []);

    return <></>;
};

export default SignIn;
