const httpService = {
    getClasses: async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_APITWO}/classcode/active`);
            return await response.json();
        } catch (error) {
            console.log('getLiveClasses() error: ', error);
        }
    },

    getClassesInRegion: async (region) => {
        try {
            const response = await fetch(`https://${region}.classpoint.app/liveclasses/summary`);
            return await response.json();
        } catch (error) {
            console.log('getClassesInRegion() error: ', error);
        }
    },

    getClassModelByEmail: async (region, email) => {
        try {
            const response = await fetch(`https://${region}.classpoint.app/liveclasses?email=${email}`);
            return await response.json();
        } catch (error) {
            console.log('getLiveClassByEmail() error: ', error);
        }
    },

    getUserModelByEmail: async (email) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_APIAPP}/users?email=${email}`);
            return await response.json();
        } catch (error) {
            console.log('getUserModelByEmail() error: ', error);
        }
    },
};

export default httpService;
