import { Box, Button, Modal, Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import httpService from '../services/httpService';
import ClassDetails from './ClassDetails';

const LiveClassesRegionView = () => {
    const [region, setRegion] = useState(useParams().region);
    const [liveClassesRegion, setLiveClassesRegion] = useState([]);
    const [email, setEmail] = useState(null);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '80%',
        overflow: 'scroll',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        let intervalId = setInterval(async () => {
            await loadLiveClasses();
        }, 5000);
        return () => clearInterval(intervalId);
    });

    const init = async () => {
        try {
            await loadLiveClasses();
        } catch (error) {
            setLiveClassesRegion([]);
        }
    };

    const loadLiveClasses = async () => {
        const liveClassesRegionDict = await httpService.getClassesInRegion(region);
        console.log('reloaded');
        setLiveClassesRegion(Object.values(liveClassesRegionDict));
    };

    return (
        <>
            <div style={{ maxWidth: '100%', margin: 'auto' }}>
                <MaterialTable
                    columns={[
                        { title: 'classCode', field: 'classCode', type: 'numeric', defaultSort: 'asc' },
                        { title: 'email', field: 'email' },
                        { title: 'country', field: 'country' },
                        { title: 'isInSlideshow', field: 'isInSlideshow' },
                        { title: 'participantCount', field: 'count', type: 'numeric' },
                        { title: 'currentActivity', field: 'activityType' },
                    ]}
                    data={liveClassesRegion}
                    title={region}
                    options={{
                        filtering: true,
                        search: false,
                        pageSize: 10,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [10, 20, 50],
                        detailPanelColumnAlignment: 'right',
                        actionsColumnIndex: -1,
                    }}
                    actions={[
                        {
                            icon: 'sync',
                            tooltip: 'Reload live classes',
                            isFreeAction: true,
                            onClick: async () => loadLiveClasses(),
                        },
                        {
                            icon: 'visibility',
                            tooltip: 'View details',
                            onClick: (event, rowData) => {
                                setEmail(rowData.email);
                                handleOpen();
                            },
                        },
                    ]}
                />
            </div>
            <Button style={{ marginTop: '10px' }} variant='text' color='primary' onClick={() => navigate('/live-classes-summary')}>
                Back to summary
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <Box sx={style}>
                    <ClassDetails region={region} email={email} />
                </Box>
            </Modal>
        </>
    );
};

export default LiveClassesRegionView;
