import { Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import httpService from '../services/httpService';

const LiveClassesSummary = () => {
    const [isRetrievingData, setIsRetrievingData] = useState(false);
    const [summary, setSummary] = useState(null);
    const [uniqueRegions, setUniqueRegions] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        try {
            setIsRetrievingData(true);
            await loadSummary();
            setIsRetrievingData(false);
        } catch (error) {
            setSummary(null);
        }
    };

    const loadSummary = async () => {
        const classesData = await httpService.getClasses();
        let summary = [];
        const uniqueRegions = classesData.map((c) => c.region).filter((element, index, array) => array.indexOf(element) === index);
        setUniqueRegions(uniqueRegions);
        uniqueRegions.forEach((region) => {
            const classesDataInRegion = classesData.filter((c) => c.region === region);
            const numClassesInRegion = classesDataInRegion.length;
            const uniqueCountriesInRegion = classesDataInRegion.map((c) => c.country).filter((element, index, array) => array.indexOf(element) === index);
            summary.push({
                region,
                numClasses: numClassesInRegion,
                country: uniqueCountriesInRegion.length,
            });
            uniqueCountriesInRegion.forEach((country) => {
                summary.push({
                    region: '',
                    numClasses: classesDataInRegion.filter((c) => c.country === country).length,
                    country,
                    parentRegion: region,
                });
            });
        });
        setSummary(summary);
    };

    return (
        <>
            {isRetrievingData ? (
                <p>Retrieving data...</p>
            ) : (
                <>
                    {summary === null ? (
                        'No liveClasses data'
                    ) : (
                        <>
                            <div style={{ maxWidth: '100%', margin: 'auto' }}>
                                <MaterialTable
                                    title='Live classes summary'
                                    data={summary}
                                    columns={[
                                        { title: 'region', field: 'region' },
                                        { title: '# classes', field: 'numClasses' },
                                        { title: 'country', field: 'country' },
                                    ]}
                                    parentChildData={(row, rows) => rows.find((a) => a.region === row.parentRegion)}
                                    options={{ emptyRowsWhenPaging: false, actionsColumnIndex: -1 }}
                                    actions={[
                                        {
                                            icon: 'sync',
                                            tooltip: 'Reload live classes',
                                            isFreeAction: true,
                                            onClick: async () => loadSummary(),
                                        },
                                    ]}
                                />
                            </div>
                            <p>
                                View region:{' '}
                                {uniqueRegions.map((region) => (
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        style={{ marginRight: '10px' }}
                                        onClick={() => navigate('/live-classes-regionview/' + region)}
                                    >
                                        {region}
                                    </Button>
                                ))}
                            </p>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default LiveClassesSummary;
