import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MsalProvider, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { EventType, InteractionType } from '@azure/msal-browser';
import { b2cPolicies, loginRequest } from './authConfig';
import SignIn from './components/SignIn';
import Header from './layout/Header';
import LiveClassesSummary from './components/LiveClassesSummary';
import LiveClassesRegionView from './components/LiveClassesRegionView';
import Navigation from './components/Navigation';

function App({ instance }) {
    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            // alert('app' + event.eventType);
            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        return instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
                    } else if (event.interactionType === InteractionType.Popup) {
                        instance.loginPopup(b2cPolicies.authorities.forgotPassword).catch((e) => {
                            return;
                        });
                    }
                }
            }

            if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                if (event?.payload) {
                    if (event.payload.idTokenClaims['acr'] === b2cPolicies.names.forgotPassword) {
                        window.alert('Password has been reset successfully. \nPlease sign-in with your new password.');
                        return instance.logout();
                    } else if (event.payload.idTokenClaims['acr'] === b2cPolicies.names.editProfile) {
                        window.alert('Profile has been edited successfully. \nPlease sign-in again.');
                        return instance.logout();
                    }
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, []);

    return (
        <Router>
            <MsalProvider instance={instance}>
                <AuthenticatedTemplate>
                    <Header instance={instance} />
                    <Routes>
                        <Route path='/live-classes-summary' element={<LiveClassesSummary />} />
                        <Route path='live-classes-regionview/:region' element={<LiveClassesRegionView />} />
                        <Route path='/' element={<Navigation />} />
                    </Routes>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <SignIn instance={instance} />
                </UnauthenticatedTemplate>
            </MsalProvider>
        </Router>
    );
}

export default App;
