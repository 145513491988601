import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import httpService from '../services/httpService';

const ClassDetails = ({ region, email }) => {
    const [classDetails, setClassDetails] = useState(null);
    const [value, setValue] = useState(0);

    useEffect(() => {
        loadClassDetails(region, email);
    }, [region, email]);

    const loadClassDetails = async (region, email) => {
        const userModel = await httpService.getUserModelByEmail(email);
        const classModel = await httpService.getClassModelByEmail(region, email);
        setClassDetails({
            userModel,
            classModel,
        });
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
            style: { textTransform: 'none' },
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {classDetails && (
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example' variant='scrollable'>
                            <Tab label='userProfile' {...a11yProps(0)} />
                            <Tab label='userSubscription' {...a11yProps(1)} />
                            <Tab label='userStats' {...a11yProps(2)} />
                            <Tab label='classStats' {...a11yProps(3)} />
                            <Tab label='currentSlideshow' {...a11yProps(4)} />
                            <Tab label='currentActivity' {...a11yProps(5)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <pre>
                            {JSON.stringify(
                                {
                                    email: classDetails.userModel.email,
                                    userProfile: classDetails.userModel.userProfile,
                                    userBadges: classDetails.userModel.userBadges,
                                },
                                null,
                                2
                            )}
                        </pre>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <pre>{JSON.stringify(classDetails.userModel.userSubscription, null, 2)}</pre>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <pre>{JSON.stringify(classDetails.userModel.userStats, null, 2)}</pre>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <pre>
                            {JSON.stringify(
                                {
                                    classSessionId: classDetails.classModel.classSessionId,
                                    startedOn: classDetails.classModel.startedOn,
                                    classLimit: classDetails.classModel.classLimit,
                                    isInSlideshow: classDetails.classModel.isInSlideshow,
                                    isLocked: classDetails.classModel.isLocked,
                                    isInPickedNames: classDetails.classModel.isInPickedNames,
                                    isSavedOnDb: classDetails.classModel.isSavedOnDb,
                                },
                                null,
                                2
                            )}
                        </pre>
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <pre>{JSON.stringify(classDetails.classModel.currentSlideshow, null, 2)}</pre>
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <pre>{JSON.stringify(classDetails.classModel.currentActivity, null, 2)}</pre>
                    </TabPanel>
                </>
            )}
        </>
    );
};

export default ClassDetails;
