import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
    return (
        <ul>
            <li>
                <Link to='/live-classes-summary'>Live classes summary</Link>
            </li>
        </ul>
    );
};

export default Navigation;
